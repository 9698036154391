import { scroller } from 'react-scroll';

export const scrollToElement = (
  id,
  { duration = 500, delay = 0, smooth = 'easeInOutQuart', offset = -100 } = {}
) => {
  scroller.scrollTo(id, {
    duration: duration,
    delay: delay,
    smooth: smooth,
    offset: offset,
  });
};

export const createHash = str => {
  const hash = str.toLowerCase().replace(/\s/, '');
  return hash.startsWith('#') ? hash : `#${hash}`;
};

export const parseHash = () => {
  return window.location.hash.replace(/^#/, '');
};

export const replaceHash = hash => {
  hash = hash ? (createHash(hash)) : "";

  if (history.replaceState) {
    let location = window.location;
    history.replaceState(
      null,
      null,
      location.pathname + location.search + hash
    );
  } else {
    location.hash = hash;
  }
};

export const findElement = id => {
  return document.getElementById(id);
}


export const truncateString = (str, maxLen=150, separator=' ', ellipsis='…') => {
  if (str.length <= maxLen) {
    return str;
  }
  return str.substr(0, str.lastIndexOf(separator, maxLen)) + ellipsis;
}

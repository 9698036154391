module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7402430b7f6140eaacea544ff2df57c8@o553932.ingest.sentry.io/5681845","environment":"production","enabled":"false"},
    },{
      plugin: require('../plugins/segment/gatsby-browser.js'),
      options: {"plugins":[],"writeKey":"5snygedwXuQcdHuXW8jdOEVRqUiQelKa","trackPage":false,"ccpaLibURL":"https://calstate.aaa.com/privacy-rights/v1/lib","ccpaApiURL":"https://calstate.aaa.com/endpoints/privacy-rights/v1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"disableBgImage":true,"maxWidth":2880}},{"resolve":"gatsby-remark-copy-linked-files","options":{}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Users\\Usuario\\EverythingElse\\AAA\\A3SH-BigCommerce\\smarthome-welcomekit"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
